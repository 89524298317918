import React from 'react';
import whiteLogo from '../assets/PiovationLogoWhite.svg';
import forward from '../assets/ForwardArrow.svg';
import tunning from '../assets/Tunning.svg';

const GetStarted = () => {
  return (
    <section className="p-0">
      <div className='d-flex gap-5 flex-row book-demo row align-items-center justify-content-center'>
        <div className="col-lg-6 col-md-12 images">
          <img className="pb-4 mb-2" style={{height: '6vh'}} src={whiteLogo}></img>
          <h4>Get Started</h4>
          <h1 className="fw-bolder" style={{ color:'#1BAE70' }}>Unlock the power of Platform</h1>
          <div className='btn ps-3 pe-3 mt-2' style={{backgroundColor: '#1AB272', color: 'white'}}> Book Demo <img className="ms-2" src={forward}></img></div>
        </div>
        <img className="col-lg-6 align-items-center chart-image chart2-image" src={tunning} alt="tunning" />
      </div>
    </section>
  );
};

export default GetStarted;
