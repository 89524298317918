import React from 'react';

const Features = () => {
  return (
    <section className="mt-5 pb-3 mb-5" id="features">
      <div className='text-center'>
        <h3 style={{ color: '#8DD7B8' , marginBottom: '14px' }}>Features</h3>
        <h2>Everything you need to Start your next AI Project</h2>
        <p style={{ color: 'grey' }}>Not just a set of tools, the package includes Streamlined Deployment, Real-time Monitoring, and Scalability with PioVation.</p>
      </div>
    </section>
  );
};

export default Features;
