import React from 'react';
import ceo from '../assets/Ceo.png';

const AboutUs = () => {
  return (
    <section className="mb-5 mt-5" id="about-us">
      <div className='text-center'>
        <h3 className="pb-2" style={{ color: '#8DD7B8' }}>About Us</h3>
        <h2>Meet the faces behind this innovation</h2>
        <p style={{ color: 'grey' }}>The driving force behind PioVation's innovation in AI and HPC solutions. Meet the experts dedicated to simplifying your technology experience.</p>
      </div>
      <div className="flex-row gy-3 mt-2 pt-3 text-center d-flex justify-content-center align-items-center">
        <div className="col-6 col-md-5 ps-lg-5 ps-0">
          <img
            className='ceo-image'
            src={ceo}
            alt="CEO" />
          <h5 className="mb-0 mt-2">Mr. Sabony</h5>
          <p style={{color: 'grey'}} className="mb-0">Chief Executive Officer (CEO)</p>
        </div>
        <div className="col-6 col-md-5 pe-lg-5 pe-2">
          <img
            className='ceo-image'
            src={ceo}
            alt="CTO" />
          <h5 className="mb-0 mt-2">Mr. Kramer</h5>
          <p style={{color: 'grey'}} className="mb-0">Chief Technical Officer (CTO)</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
