import React from 'react';
import oval from '../assets/OvalLogoispum.svg';
import chat from '../assets/ChatLogoipsum.svg';
import thunder from '../assets/ThunderLogoispum.svg';
import fan from '../assets/FanLogoispum.svg';
import spring from '../assets/SpringLogoispum.svg';

const TrustedBrands = () => {
  return (
    <section className="pb-5 pt-2">
      <div className='text-center'>
        <h3 style={{ color: '#8DD7B8' }}>Trusted By Leading Brands Worldwide</h3>
        <div className='logos-container mt-5'>
          <div className='logos gap-3 flex-row'>
            <img src={oval} alt="oval logo" className="logo" />
            <img src={chat} alt="chat logo" className="logo" />
            <img src={thunder} alt="thunder logo" className="logo" />
            <img src={fan} alt="fan logo" className="logo" />
            <img src={spring} alt="spring logo" className="logo" />
          </div>
          <div className='logos gap-3 flex-row'>
            <img src={oval} alt="oval logo" className="logo" />
            <img src={chat} alt="chat logo" className="logo" />
            <img src={thunder} alt="thunder logo" className="logo" />
            <img src={fan} alt="fan logo" className="logo" />
            <img src={spring} alt="spring logo" className="logo" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedBrands;
