import React from 'react';
import devices from '../assets/Devices.svg';

const CostEffectiveResource = () => {
  return (
    <section className="pb-lg-5 pb-2 mb-3">
      <div className='d-flex gap-5 flex-row row align-items-center justify-content-center'>
        <div className="col-lg-6 col-md-12 images align-items-center pt-lg-4 order-2 order-lg-1 p-0">
          <div className='btn mb-3 ps-4 pe-4' style={{ backgroundColor: '#3E4E47', color: '#1AB272' }}>Cost-Efficient</div>
          <h3>Cost-Effective Resource Utilization</h3>
          <ul>
            <li>Intelligent workload balancing</li>
            <li>Optimal resource allocation</li>
            <li>Cost-effective performance optimization</li>
            <li>Minimization of unnecessary expenses</li>
            <li>Maximized return on investment</li>
          </ul>
        </div>
        <img className="col-lg-6 col-md-12 images order-1 order-lg-2" src={devices} alt="devices" />
      </div>
    </section>
  );
};

export default CostEffectiveResource;
