import React from 'react';
import '../MainContent.css';
import WhyChooseUs from './WhyChooseUs';
import TrustedBrands from './TrustedBrands';
import Features from './Features';
import EffortlessDeployment from './EffortlessDeployment';
import RealTimeMonitoring from './RealTimeMonitoring';
import VersatileAPISupport from './VersatileAPISupport';
import CostEffectiveResource from './CostEffectiveResource';
import AboutUs from './AboutUs';
import GetStarted from './GetStarted';

const MainContent = () => {
  return (
    <main className="main-content align-items-center">
      <WhyChooseUs />
      <TrustedBrands />
      <Features />
      <EffortlessDeployment />
      <RealTimeMonitoring />
      <VersatileAPISupport />
      <CostEffectiveResource />
      <AboutUs />
      <GetStarted />
    </main>
  );
};

export default MainContent;
