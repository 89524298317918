import React from 'react';
import performance from '../assets/Performance.svg';

const RealTimeMonitoring = () => {
  return (
    <section className="pb-5">
      <div className='d-flex gap-5 flex-row row align-items-center justify-content-center'>
        <div className="col-lg-6 col-md-12 images align-items-center pt-lg-3 order-lg-1 order-2">
          <div className='btn mb-3 ps-4 pe-4' style={{ backgroundColor: '#3E4E47', color: '#1AB272' }}>Full Visibility</div>
          <h3>Real-time Monitoring and Optimization</h3>
          <ul>
            <li>Real-time monitoring of hardware and applications</li>
            <li>Empowering administrators and users</li>
            <li>Tracking AI workload performance</li>
            <li>Proactive optimization capabilities</li>
            <li>Ensuring optimal efficiency and responsiveness</li>
          </ul>
        </div>
        <img className="col-lg-6 col-md-12 images order-lg-2 order-1" src={performance} alt="performance" />
      </div>
    </section>
  );
};

export default RealTimeMonitoring;
