import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className="App">
      <Header />
      <MainContent />
      <Footer />
      <hr style={{ width: '100%', border: '1 rgba(219, 219, 235, 0.12)'}}></hr>
      <div>
        <p className='text-center mt-2 small' style={{color: 'grey', textWrap: 'nowrap'}}>© 2024 Piovation.com Ⓡ All rights Reserved</p>
      </div>
    </div>
  );
}

export default App;
