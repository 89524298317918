import React from 'react';
import chart from '../assets/Component13.svg';
import setting from '../assets/IcRoundSettings.svg';
import ai from '../assets/Ai.svg';
import good from '../assets/GppGood.svg';

const WhyChooseUs = () => {
  return (
    <section className="pb-5 mb-3 pt-5 pt-lg-0 mt-5" id="why-us">
      <div className='text-center'>
        <h4 style={{ color: '#1AB272', marginBottom: '14px' }}>Why Choose Us</h4>
        <h3>Efficient AI Workloads Simplified</h3>
        <p style={{ color: 'grey' }}>Streamlined AI Efficiency with Scalability. Simplified setup, real-time monitoring, adaptable deployment, and a focus on security optimize resource utilization for efficient and scalable AI workloads.</p>
      </div>
      <div className='d-flex flex-row mt-5 row align-items-center justify-content-center'>
        <img className="col-lg-6 col-md-12 chart-image p-0" src={chart} alt="chart" style={{width:' 55%', height: '65vh'}} />
        <div className="col-lg-6 col-md-12 simplified-setup ps-lg-2 pt-5" >
          <div className="d-flex align-items-center mb-3">
            <img src={setting} alt="setting" />
            <h5 className="d-inline ps-2">Simplified Setup</h5>
          </div>
          <p className="small ps-2">Provisioning of Converged HPC-AI compute environment from on-/../publicpremise to any cloud.</p>
          <div className="d-flex align-items-center mb-3">
            <img src={ai} alt="ai" />
            <h5 className="d-inline ps-2">Intuitive AutoML</h5>
          </div>
          <p className="small ps-2">When you add work to your Slate calendar we automatically calculate useful insights.</p>
          <div className="d-flex align-items-center mb-3">
            <img src={good} alt="good" />
            <h5 className="d-inline ps-2">Security and Optimization</h5>
          </div>
          <p className="small ps-2">Prioritizes data security and workload efficiency, with real-time monitoring for ongoing optimization.</p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
