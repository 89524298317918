import React from 'react';
import DataExtraction from '../assets/DataExtraction.svg';

const EffortlessDeployment = () => {
  return (
    <section className="pb-5">
      <div className='d-flex gap-lg-5 flex-row row align-items-center justify-content-center'>
        <img className="col-lg-6 col-md-12 images" src={DataExtraction} alt="data extraction" />
        <div className="col-lg-6 col-md-12 images align-items-center mt-5 pt-lg-3">
          <div className='btn mb-3' style={{ backgroundColor: '#3E4E47', color: '#1AB272' }}>Simplified Setup</div>
          <h3>Effortless Deployment</h3>
          <ul>
            <li>Simplified AI workload setup</li>
            <li>Seamless deployment of AI systems</li>
            <li>Effortless AI management</li>
            <li>Elimination of backend configuration complexities</li>
            <li>User-friendly AI system management</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default EffortlessDeployment;
